import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import TinyPhoto from "../components/TinyPhoto/TinyPhoto";
import config from "../../data/SiteConfig";

class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`About ♡ ${config.siteTitle}`} />
          <div>
            <h1>About</h1>
            <TinyPhoto src="/img/IMG_9897.JPG" alt="" size="medium" float="center" />
            <p>
              Hello! I'm your standard blogger who enjoys talking about cute and happy things, sharing thoughts and memories, and cooking avocado toast.
            </p>
            <p>
              See you around <img src="/emoji/heart14.gif" alt="" />
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;